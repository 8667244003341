export const Endpoints = {
  users: 'users',
  authenticate: 'authenticate',
  collections: 'collection',
  timePartition: 'time-partition',
  timePeriod: 'time-period',
  refreshToken: 'refresh',
  project: 'project',
  views: 'views',
  studyArea: 'studyarea',
  equity: 'equity',
  product: 'product',
  studyPeriod: 'studyperiod',
  equityDefintion: 'equityDefinition',
  zoneSystem: 'zonesystem',
  mapLayer: 'mapLayer',
  favourite: 'favourite',
  accessRoles: 'accessRoles',
  entityAccess: 'entityAccess',
  states: 'states',
  counties: 'counties',
  clients: 'clients',
  roles: 'roles',
  emails: 'emails',
  document: 'document',
  databricks: 'databricksjobs',
  dashboard: 'dashboards',
  filedetails: 'filedetails'
};
