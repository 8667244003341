import moment from 'moment';
import * as R from 'ramda';
import { User } from '../types/user';
import { ProjectCreationType } from '../types/table';
import { tableTabs } from '../constants';

export const getPathname = (path: string) => {
  let fetchedPath = '';
  if (path?.includes('project')) {
    fetchedPath = 'projects';
  } else {
    fetchedPath = path.substring(path.indexOf('/') + 1,
      path.lastIndexOf('/') === 0 ? path.length : path.lastIndexOf('/'));
  }
  return fetchedPath;
};

export const generateKey = () => Math.random().toString(16).slice(2);

export const formatDate = (date?: string | Date, short?: boolean) => (
  short ? moment(date).format('MMM DD, YYYY') : moment(date).format('MMMM DD, YYYY')
);

export const updateClientUserData = (clientUsers: any, user: User) => {
  const updatedData = clientUsers?.map((item: ProjectCreationType) => ({
    ...item,
    entityRole: item?.id === user?.id ? 'Creator' : ''
  }));

  return updatedData ?? [];
};

export const sortTimePeriodByTableHeader = (data: any, userId: string, sort?: { name: string, order: string }) => {
  data?.sort((itemA: any, itemB: any) => {
    const timePartitionA = itemA?.timePartition;
    const timePartitionB = itemB?.timePartition;

    const dateA: any = sort ? new Date(timePartitionA?.[sort?.name]) : new Date(data?.createdAt);
    const dateB: any = sort ? new Date(timePartitionB?.[sort?.name]) : new Date(data?.createdAt);

    const hasPriorityIdA = timePartitionA?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);
    const hasPriorityIdB = timePartitionB?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);

    if (hasPriorityIdA && !hasPriorityIdB) {
      return -1;
    }

    if (!hasPriorityIdA && hasPriorityIdB) {
      return 1;
    }

    if (sort?.name === 'name') {
      if (sort?.order === 'DESC') {
        return timePartitionA?.[sort.name]?.toLowerCase() < timePartitionB?.[sort.name]?.toLowerCase() ? 1 : -1;
      }
      return timePartitionA?.[sort.name]?.toLowerCase() > timePartitionB?.[sort.name]?.toLowerCase() ? 1 : -1;
    }
    if (sort?.order === 'DESC') {
      return dateB - dateA;
    }
    return dateA - dateB;
  });

  return data;
};

export const sortByTableHeader = (data: any, userId: string, sort?: { name: string, order: string }) => {
  data.sort((itemA: any, itemB: any) => {
    const dateA: any = sort ? new Date(itemA?.[sort?.name]) : new Date(data?.createdAt);
    const dateB: any = sort ? new Date(itemB?.[sort?.name]) : new Date(data?.createdAt);

    const hasPriorityIdA = itemA?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);
    const hasPriorityIdB = itemB?.favourites?.find((favourite: any) => favourite?.favouritedBy === userId);

    if (hasPriorityIdA && !hasPriorityIdB) {
      return -1;
    }

    if (!hasPriorityIdA && hasPriorityIdB) {
      return 1;
    }

    if (sort?.name === 'name') {
      if (sort?.order === 'DESC') {
        return itemA?.[sort.name]?.toLowerCase() < itemB?.[sort.name]?.toLowerCase() ? 1 : -1;
      }
      return itemA?.[sort.name]?.toLowerCase() > itemB?.[sort.name]?.toLowerCase() ? 1 : -1;
    }
    if (sort?.order === 'DESC') {
      return dateB - dateA;
    }
    return dateA - dateB;
  });

  return data;
};

export const compareDates = (prevDate: string, nxtDate: string) => (
  moment(prevDate) > moment(nxtDate) ? 1 : -1
);

export const getProjectUsedInNames = (data: any) => {
  const displayCount: any = 10;
  const dataByCount: any = R.take(displayCount, data);
  let result: any = R.pipe(
    R.map(R.prop('name') as any),
    R.join(', ')
  )(dataByCount);
  if (data?.length > displayCount) {
    result += `, ..., ${data?.length - displayCount} more`;
  }
  return result;
};

export const getDataByType: any = (all: any, shared: any, owned: any, type: string) => {
  const datasources = {
    [tableTabs.all]: all,
    [tableTabs.owned]: owned,
    [tableTabs.shared]: shared
  };

  /* eslint no-underscore-dangle: 0 */
  const selectedData: any = datasources[type] ?? [];
  const count = Math.ceil((selectedData?.length || 0) / 10) * 10;

  return { selectedData, count };
};

export const updateOptions = (options: any) => options.filter((item: any) => item.isAvailable === true);

export const getDarkColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

export const handleTableSortWithOrder = (key: string, tableSort: any) => {
  const data: any = { ...tableSort };
  if (!data.sorterOrder) {
    data.order = 'ASC';
    data.sorterOrder = 'ascend';
  } else if (data.sorterOrder === 'ascend') {
    data.order = 'DESC';
    data.sorterOrder = 'descend';
  } else {
    data.order = 'DESC';
    data.sorterOrder = false;
  }
  data.name = key;
  return data;
};

export const handleTableSort = (key: string, tableSort: any) => {
  const data: any = { ...tableSort };
  if (data.order === 'ASC') {
    data.order = 'DESC';
  } else {
    data.order = 'ASC';
  }
  data.name = key;
  return data;
};

export const removeEnvironmentValueFromClientName = (clientName: string) => {
  const valuesToBeRemoved = ['dev', 'development'];
  let replacedString = clientName;
  valuesToBeRemoved.forEach((value: string) => {
    if (clientName.includes(value)) replacedString = clientName.replace(`${value}_`, '');
  });
  return replacedString;
};
