import axios from 'axios';
import { baseURL } from '../config';
import { Tokens, messages } from '../constants';
import { refreshToken } from './requests/refreshToken';
import KeycloakServices from '../Keycloak-config';

const authToken = () => localStorage.getItem(Tokens.accessToken);
const client = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken()}`,
    Accept: 'application/json'
  },
});

client.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${authToken()}`;
  return config;
});

client.interceptors.response.use((res) => res, async (err) => {
  const originalRequest = err.config;
  if (err?.response?.status === 401 && (err?.response?.data === messages.refreshTokenExpired || err?.response?.data === messages?.sessionNotActive || err?.response?.data === messages?.usernotFound || err?.response?.data === messages?.invalidRefreshToken)) {
    localStorage.removeItem(Tokens.accessToken);
    localStorage.removeItem(Tokens.refreshToken);
    KeycloakServices.logout();
  } else if (err && err?.response && err?.response?.status === 401) {
    try {
      const refreshData = await refreshToken(localStorage.getItem(Tokens.refreshToken) as string);
      if (refreshData) {
        localStorage.setItem(Tokens.accessToken, refreshData?.data?.accessToken);
        originalRequest.headers.Authorization = `Bearer ${refreshData.data.accessToken}`;
        return await client(originalRequest);
      }
    } catch {
      return true;
    }
  }

  return Promise.reject(err);
});

export { client };
