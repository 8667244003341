import { TabsProps } from 'antd';
import i18next from 'i18next';

export const Tokens = {
  accessToken: 'access-token',
  refreshToken: 'refresh-token'
};

export const transparencyOptions = [
  { value: 0.1, label: '10%' },
  { value: 0.2, label: '20%' },
  { value: 0.3, label: '30%' },
  { value: 0.4, label: '40%' },
  { value: 0.5, label: '50%' },
  { value: 0.6, label: '60%' },
  { value: 0.7, label: '70%' },
  { value: 0.8, label: '80%' },
  { value: 0.9, label: '90%' },
  { value: 1, label: '100%' },
  { value: 0.2, label: '20%' }
];

export const pointSizeOptions = [
  { value: '2px', label: '2px' },
  { value: '4px', label: '4px' },
  { value: '6px', label: '6px' },
  { value: '8px', label: '8px' },
  { value: '10px', label: '10px' },
];

export const mapLayerShapes = {
  point: 'circle',
  line: 'line',
  polygon: 'fill'
};

export const mapLayerShapeOptions = [
  { value: 'circle', label: 'Point' },
  { value: 'line', label: 'Line' },
  { value: 'fill', label: 'Polygon' }
];

export const projectOptions: TabsProps['items'] = [
  {
    key: 'All',
    label: `${i18next.t('projects.allProjects')}`,
  },
  {
    key: 'Owned',
    label: `${i18next.t('projects.ownedProjects')}`,
  },
  {
    key: 'Shared',
    label: `${i18next.t('projects.sharedProjects')}`,
  },
];

export const enum Roles {
  SITE_ADMIN = 'SITE_ADMIN',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  USER = 'USER'
}

export const tableTabs = {
  owned: 'Owned',
  all: 'All',
  shared: 'Shared'
};

export const zipFileTypes: any = [
  'application/zip',
  'multipart/x-zip',
  'application/zip-compressed',
  'application/x-zip-compressed',
  'application/x-zip',
  'application/octet-stream'
];

export const csvFileTypes: any = [
  'text/csv',
  'application/csv',
  'application/octet-stream',
  'application/vnd.ms-excel'
];

export const enum Permissions {
  ADD_USER = 'ADD_USER',
  UPDATE_USER = 'UPDATE_USER',
  GET_USER = 'GET_USER',
  DELETE_USER = 'DELETE_USER',
  ADD_SITE_ADMIN = 'ADD_SITE_ADMIN',
  UPDATE_SITE_ADMIN = 'UPDATE_SITE_ADMIN',
  GET_SITE_ADMIN = 'GET_SITE_ADMIN',
  DELETE_SITE_ADMIN = 'DELETE_SITE_ADMIN',
  ADD_CLIENT_ADMIN = 'ADD_CLIENT_ADMIN',
  UPDATE_CLIENT_ADMIN = 'UPDATE_CLIENT_ADMIN',
  GET_CLIENT_ADMIN = 'GET_CLIENT_ADMIN',
  DELETE_CLIENT_ADMIN = 'DELETE_CLIENT_ADMIN'
}

export const messages = {
  tokenNotActive: 'Token is not active',
  refreshTokenExpired: 'Refresh token expired',
  sessionNotActive: 'Session not active',
  sessionExpired: 'Session expired',
  usernotFound: 'User not found',
  invalidRefreshToken: 'Invalid refresh token'
};

export const displayTypes = {
  list: 'List',
  card: 'Card'
};

export const Urls = {
  stamenDevUrl: 'https://studio.stamen.com/camsys-map-prototype/show/latest/#lng=-82.69&lat=39.99&zoom=6.5&dashboard=e73a817c-2288-4f1d-99fc-675cb5d47cc7',
  zesstaPassengerDevUrl: 'https://locus-passenger-dev.zessta.com/#lng=-82.69&lat=39.99&zoom=6.5&dashboard=e73a817c-2288-4f1d-99fc-675cb5d47cc7'
};

export const timeBounds = {
  startTime: 'start-time',
  endTime: 'end-time'
};

export const themeTabs = [
  {
    key: 'colorPrimary',
    label: 'ColorPrimary'
  },
  {
    key: 'colorBgBase',
    label: 'ColorBgBase'
  },
  {
    key: 'colorTextBase',
    label: 'ColorTextBase'
  },
  {
    key: 'colorSuccess',
    label: 'ColorSuccess'
  },
  {
    key: 'colorError',
    label: 'ColorDanger'
  },
  {
    key: 'colorWarning',
    label: 'ColorWarning'
  }
];

export const pageDescription = [
  'Select the data product you’d like to include in this project.',
  'Define a study area from the options below. The selected study area will be applied to all products in this project.',
  'Select the time of day segmentation you’d like to use for this project.',
  'Select the study period you’d like to use for this project.',
];

export const productDescription: string[] = [
  'Origin-Destination passenger flows by zone, mode, travel purpose, time, and more.',
  'Identifies ideal locations for public charging stations for passenger and truck electric vehicles.',
  'Roadway link based dashboard - shows speeds, patterns, and volumes, external station, select-link analyses, and pass-through zones on specific event days.',
  'Indicates areas with higher exposure to crashes by linking observed crash data with LOCUS Passenger and Truck flows.',
  'Origin-Destination truck flows segmented by commodity flows.'
];

export const projectCreationStepsId = {
  zoneSystem: 'zoneSystemIds',
  dashboardEquityDefinition: 'dashboardEquityDefinitionId',
  mapLayerId: 'mapLayerId'
};

export const mapLayerOptions = [
  {
    name: 'Transit Stations',
    id: '1'
  },
  {
    name: 'Bus routes',
    id: '2'
  },
  {
    name: 'Colleges/Universities',
    id: '3'
  },
  {
    name: 'Distribution centers',
    id: '4'
  }
];

export const MAPBOX_STYLE_URLS = {
  light: 'mapbox://styles/locus-cs/cllpdr7xd002o01of6pqchr55/draft',
  dark: 'mapbox://styles/locus-cs/cllpdrrc0002n01rd88nz8tf5/draft',
  street: 'mapbox://styles/mapbox/streets-v12'
};

export const MAP_ACCESS_TOKEN = 'pk.eyJ1IjoibG9jdXMtY3MiLCJhIjoiY2xscDlrbmY3MDQzdTNmbzVsa2QwOXF1eSJ9.C0aPmSKbycVfCXQ3h6KcBg';

export const MAPBOX_TILESET_BLOCKGROUPS = {
  url: 'mapbox://locus-cs.6967y69s',
  layerName: 'usblockgroups',
};

export const defaultStudyAreaName = 'Untitled Study Area';

export const pointMapStyle: any = {
  'circle-color': '#532CDD',
  'circle-opacity': 1,
  'circle-radius': 3,
  'circle-stroke-color': '#532CDD'
};

export const lineMapStyle: any = {
  'line-color': '#AC29D1',
  'line-opacity': 1,
  'line-width': 2.5
};

export const ploygonMapStyle: any = {
  'fill-color': '#DB47E6',
  'fill-opacity': 0.5,
  'fill-outline-color': '#000000'
};

export const accessPermissions: any = {
  Viewer: ['favorite'],
  Editor: ['edit', 'favorite', 'share'],
  Owner: ['edit', 'share', 'favorite', 'delete']
};

export const viewAccessPermissions: any = {
  Viewer: ['favorite'],
  Editor: ['favorite', 'share'],
  Owner: ['edit', 'share', 'favorite', 'delete']
};

export const collectionViewAccessPermissions: any = {
  Viewer: ['favorite'],
  Editor: ['favorite', 'share'],
  Owner: ['edit', 'share', 'favorite', 'remove'],
  ViewOwner: ['edit', 'share', 'favorite', 'remove'],
};

export const projectViewAccessPermissions: any = {
  Viewer: ['favorite'],
  Editor: ['favorite', 'share'],
  Owner: ['share', 'favorite', 'delete']
};

export const mapLayerAccessPermissions = ['edit', 'delete'];
export const zoneSystemAccessPermissions = ['delete'];
export const equityAccessPermissions = ['delete'];
export const accessPermissionsTimePartitions: any = {
  Viewer: [],
  Editor: ['share'],
  Owner: ['share', 'delete']
};

export const accessPermissionsStudyArea: any = {
  Viewer: [],
  Editor: ['edit', 'share'],
  Owner: ['edit', 'share', 'delete']
};

export const publicStatus = {
  None: 'none',
  Organization: 'organization',
  Environment: 'environment'
};

export const emptyDataMessage = {
  loading: 'Loading...',
  timeOfDay: "You don't have any time of day",
  user: "You don't have any user",
  project: "You don't have any project",
  view: "You don't have any view",
  collection: "You don't have any collection",
  studyArea: "You don't have any study area",
  zoneSystem: "You don't have any zone system",
  equity: "You don't have any equity",
  client: "You don't have any client",
  mapLayer: "You don't have any map layer"
};

export const favouriteToastMessage = {
  added: 'added to favorites',
  remove: 'removed from favorites'
};

export const accessRoles = {
  owner: 'Owner',
  viewer: 'Viewer',
  editor: 'Editor'
};

export const dashboardPendingStatus = ['In Progress', 'InProgress', 'Failed'];

export const maxDashboardLimit = 1000;

export const sampleFileUrls = {
  equity: 'https://camsys-data-portal.s3.amazonaws.com/sampleFiles/sampleEquity.csv'
};
