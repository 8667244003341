import React, { ReactNode } from 'react';
import { Navigate } from 'react-router';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;
  if (localStorage.getItem('access-token')) {
    return <Navigate to="/home" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default AuthGuard;
