import { ReactNode, createContext, useContext, useState } from 'react';

interface State {
  isCollapse: boolean,
  handleCollapse: (val: boolean) => void
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  isCollapse: false,
  handleCollapse: (val: boolean) => { }
};

const CollapseContext = createContext<State>({
  ...initialState
});

export const CollapseProvider: React.FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  return (
    <CollapseContext.Provider
      value={{
        isCollapse,
        handleCollapse: setIsCollapse
      }}
    >
      {children}
    </CollapseContext.Provider>
  );
};

export const useCollapse = () => useContext(CollapseContext);
