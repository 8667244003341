import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Typography } from 'antd';
import { TailSpin } from 'react-loader-spinner';

import useAuth from '../hooks/useAuth';
import { getS3Name, handleLogout } from '../static/common';
import { Roles } from '../constants';
import { getUserById, updateUserAgreement } from '../api/requests/user';
import useCustomToken from '../hooks/useCustomToken';

const { Paragraph, Text } = Typography;

const StyledViewer = styled.div`
  width: 100%;
  border: none;
`;

const StyledModal = styled(Modal)`
  && .ant-modal-content {
    border-radius: 4px;
  }
  && .ant-modal-footer {
    text-align: center;
  }
  && .ant-modal-footer button {
    box-shadow: none;
  }
  && div[data-testid="tail-spin-loading"] {
    justify-content: center;
  }
`;

export const PdfViewer = () => {
  const token = useCustomToken();
  const { user } = useAuth();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);

  const getUserDetails = async () => {
    setIsLoader(true);
    const details = await getUserById(user?.id ?? '');
    if (details) {
      setIsModal(!details?.data?.eula?.current?.accepted && (details?.data?.role?.name !== Roles.SITE_ADMIN)
        && details?.data?.eula?.current?.fileId);
      setIsLoader(false);
    }
  };

  const submitHandler = async () => {
    setIsLoader(true);
    const details = await updateUserAgreement(user?.id ?? '');
    if (details?.data) {
      getUserDetails();
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [user]);

  return (
    <StyledModal
      title="Terms and Conditions"
      centered
      open={isModal}
      onOk={submitHandler}
      onCancel={handleLogout}
      closeIcon={false}
      okText="I Agree"
      maskClosable={false}
    >
      {
        (isLoader) ? (
          <TailSpin
            color={token?.colorTextTertiary}
          />
        ) : (
          <StyledViewer>
            <Paragraph>
              Welcome to LOCUS!
            </Paragraph>
            <Paragraph>
              {user?.eula?.current?.summary}
            </Paragraph>
            <Text>
              {'The license agreement can be accessed '}
            </Text>
            <a
              href={getS3Name(user?.eula?.current?.fileId)}
              download={user?.eula?.current?.fileId?.split('/')?.[1]}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </StyledViewer>
        )
      }
    </StyledModal>
  );
};
