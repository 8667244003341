import Keycloak from 'keycloak-js';
import { keycloakConfig } from './config';
import { Tokens } from './constants';

const keycloak = new Keycloak(keycloakConfig as any);

const initKeycloak = async (callback: any) => {
  keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: false
  })
    .then((authenticated: boolean) => {
      if (authenticated) {
        localStorage.setItem(Tokens.accessToken, keycloak?.token as string);
        localStorage.setItem(Tokens.refreshToken, keycloak?.refreshToken as string);
      }
      /**
             * Initializes Keycloak instance and calls the provided callback function.
             *
             * @param callback
             */
      callback();
    });
};

const { login, logout } = keycloak;

const KeycloakServices = {
  initKeycloak,
  login,
  logout
};

export default KeycloakServices;
