import * as R from 'ramda';

export const hasAnyAuthority = (permissions: string[], hasAnyPermissions: string[]) => R.cond([
  [
    () => permissions && permissions.length !== 0,
    () => {
      if (hasAnyPermissions?.length === 0) {
        return true;
      }
      return hasAnyPermissions && R.not(R.isEmpty(R.intersection(hasAnyPermissions, permissions)));
    }
  ],
  [R.T, R.always(false)]
])();
