import React, { ReactNode } from 'react';
import { Navigate } from 'react-router';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children } = props;
  if (!localStorage.getItem('access-token')) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default GuestGuard;
