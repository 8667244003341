import _ from 'lodash';
import { REACTS3URL } from '../config';
import { publicStatus, Roles, Tokens } from '../constants';
import KeycloakServices from '../Keycloak-config';

export interface IconProps {
  fill?: string
}

export const locusTokenOverrides = {
  colorBgBase: '#FAFAFA',
  colorPrimary: '#0D5C80',
  colorTextBase: '#3A3748',
  colorSuccess: '#28B67C',
  colorWarning: '#FADB14',
  fontFamily: 'Inter',
  whiteColor: '#FFFFFF',
  colorError: '#EA3E3E'
  // using colorTextTertiary token rgba(58, 55, 72, 0.65) #3A374873 in place of #8A8A8E
  // using colorTextQuaternary token rgba(63, 77, 85, 0.25) #3F4D5540 in place of #DBDFE1
};

export const handleLogout = () => {
  KeycloakServices.logout();
  localStorage.removeItem(Tokens.accessToken);
  localStorage.removeItem(Tokens.refreshToken);
};

export const handleFavourite = (id: string, tableData: any) => {
  const data = [...tableData];
  const updatedData = data?.map((item: any) => ({
    ...item,
    isFavourite: item?.id === id ? !item?.isFavourite : item?.isFavourite
  }));
  return updatedData;
};

export const sortByFavorite = (data: any) => {
  const favouriteData = data?.filter((itm: any) => itm?.isFavourite);
  const remainingData = data?.filter((itm: any) => !itm?.isFavourite);
  return data?.length > 0 ? [...favouriteData, ...remainingData] : [];
};

export const optionArray = (arr: any[], key?: number | string) => (
  arr?.map((itm: any, ind: number) => ({ id: itm?.geoId, title: itm?.name, key: key ? key?.toString()?.concat(`-${ind.toString()}`) : ind.toString() }))
);

export const dropdownMenuOptions = (arr: any) => (
  arr.map((itm: any) => ({
    label: itm?.name,
    value: itm?.id
  }))
);

export const userIdentifier = (arr: any, id: string) => (
  arr?.find((item: any) => item?.favouritedBy === id) ? true : false
);

export const sliceForPagination = (arr: any, page: number, size?: number) => (
  arr?.slice(((page - 1) * 10), ((page - 1) * 10) + (size ?? 10))
);

export const handleSearch = (arr: any, val: string) => (
  val?.trim()?.length > 0 ? arr?.filter((item: any) => {
    const name = item?.name ?? item?.firstName + item?.lastName;
    return name ? name?.toLowerCase()?.includes(val?.toLowerCase()) : true;
  }) : arr
);

export const handleTimeOfDaysSearch = (arr: any, val: string) => (
  val?.length > 0 ? arr?.filter((item: any) => item?.timePartition?.name?.toLowerCase()?.includes(val?.toLowerCase())) : arr
);

export const getStatusColor = (val: string) => (
  val === 'Active' ? 'success' : val === 'Paused' ? 'warning' : 'danger'
);

export const getRoleName = (val: string) => (
  val ? val?.toLowerCase()?.replaceAll('_', ' ') : '-'
);

export const getS3Name = (val: string) => REACTS3URL + val;

export const handleCountyValidation = (stateCounty?: any, handleValidation?: any) => (
  stateCounty?.countyGeoIds?.length === 0 ? (
    stateCounty?.stateGeoIds ? stateCounty?.stateGeoIds?.length === 0
      : handleValidation()
  ) : handleValidation()
);

export const getPublicStatus = (val: boolean, userRole: string) => (
  val ? userRole === Roles.SITE_ADMIN ? publicStatus.Environment
    : publicStatus.Organization : publicStatus.None
);

export const handleDropdownSearch = (ev: string, option: any) => (
  ev?.trim()?.length > 0 ? option?.label?.toLowerCase()?.includes(ev?.toLowerCase()) : true
);

export const getStudyAreaOptions = (includeExistingStudyAreas: boolean, disableNewStudyAreaCreation: boolean) => {
  const existingStudyArea = {
    id: '1',
    name: 'Existing study area',
    desc: 'Choose from the study areas already imported and defined in your data portal',
    disabled: false
  };

  const listCards = [
    {
      id: '2',
      name: 'Selected states / counties',
      desc: 'Search and select counties by state',
      disabled: disableNewStudyAreaCreation
    },
    {
      id: '3',
      name: 'New study area',
      desc: 'Create a new study area by uploading a  shape file or drawing a shape on a map',
      disabled: disableNewStudyAreaCreation
    },
  ];
  return includeExistingStudyAreas ? [existingStudyArea, ...listCards] : listCards;
};

export const getOwnedDetails = (arr: any, id: string) => (
  arr?.length ? arr?.filter((item: any) => (item?.ownerId === id) || (item?.timePartition?.ownerId === id)) : []
);

export const getViewCount = (arr: any) => {
  let count = 0;
  arr?.length > 0 ? arr?.map((item: any) => {
    count += item?.views?.length ?? 0;
    return item;
  }) : 0;
  return count;
};

export const removeEditFromDropdown = (arr: any) => (
  arr?.length > 0 ? arr?.filter((item: any) => item !== 'edit') : arr
);

export const dropdownOptionsForStringArray = (arr: any) => (
  arr?.length > 0 ? arr?.map((item: any) => ({ label: item, value: item })) : []
);

export const removeDuplicates = (arr: any) => (
  arr?.length > 0 ? _.uniqWith(arr, _.isEqual) : arr
);

export const removeDuplicatesForView = (arr: any) => (
  arr?.length > 0 ? _.uniqBy(arr, 'id') : arr
);

export const removeDeleteFromDropdown = (arr: any, isPublic: boolean) => (
  arr?.length > 0 && isPublic ? arr?.filter((item: any) => item !== 'delete') : arr
);
