import { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { hasAnyAuthority } from '../utils/hasAuthority';
import { Tokens } from '../constants';
import { PdfViewer } from '../components/PdfViewer';

interface RoleBasedGuardProps {
  children: ReactNode;
  RoutePermissions?: string[];
}

const RoleBasedGuard = (props: RoleBasedGuardProps) => {
  const { children, RoutePermissions } = props;
  const { permissions } = useAuth();

  if (RoutePermissions && !hasAnyAuthority(permissions, RoutePermissions)) {
    return <Navigate to="/login" />;
  }

  if (!localStorage.getItem(Tokens.accessToken)) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <PdfViewer />
      {children}
    </>
  );
};

export default RoleBasedGuard;
