import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import ErrorGuard from '../guards/ErrorGuard';
import RoleBasedGuard from '../guards/RolebasedGuard';
import { Permissions } from '../constants';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingScreen = Loadable(lazy(() => import('../components/LandingScreen')));
const DashboardLayout = Loadable(lazy(() => import('../components/DashboardLayout')));
const Dashboard = Loadable(lazy(() => import('../components/Dashboard')));
const CreateUserForm = Loadable(lazy(() => import('../pages/Create-User')));

const Home = Loadable(lazy(() => import('../pages/Home')));
const StudyArea = Loadable(lazy(() => import('../pages/StudyArea')));
const Projects = Loadable(lazy(() => import('../pages/Projects')));
const PassengerView = Loadable(lazy(() => import('../pages/PassengerView')));
const Collections = Loadable(lazy(() => import('../pages/Collections')));
const CollectionsViewPage = Loadable(lazy(() => import('../pages/CollectionsViewPage')));
const TimePeriod = Loadable(lazy(() => import('../pages/TimePeriod')));
const MapLayers = Loadable(lazy(() => import('../pages/MapLayers')));
const ZoneSystems = Loadable(lazy(() => import('../pages/ZoneSystem')));
const Equity = Loadable(lazy(() => import('../pages/Equity')));
const CreateMapLayer = Loadable(lazy(() => import('../pages/CreateNewMapLayer')));
const UpdateMapLayer = Loadable(lazy(() => import('../pages/UpdateMapLayer')));
const CreateNewEquity = Loadable(lazy(() => import('../pages/CreateNewEquity')));
const CreateNewStudyArea = Loadable(lazy(() => import('../pages/CreateNewStudyArea')));
const CreateNewZoneSystem = Loadable(lazy(() => import('../pages/CreateNewZoneSystem')));
const UserGuides = Loadable(lazy(() => import('../pages/UserGuides')));
const UseCases = Loadable(lazy(() => import('../pages/UseCases')));
const FAQs = Loadable(lazy(() => import('../pages/FAQs')));
const ContactSupport = Loadable(lazy(() => import('../pages/ContactSupport')));
const Theme = Loadable(lazy(() => import('../pages/Theme')));
const ProjectCreation = Loadable(lazy(() => import('../pages/ProjectCreation')));
const Clients = Loadable(lazy(() => import('../pages/Clients')));
const ClientCreation = Loadable(lazy(() => import('../pages/ClientCreation')));
const Users = Loadable(lazy(() => import('../pages/Users')));
const Organization = Loadable(lazy(() => import('../pages/Organization')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const TruckDashboard = Loadable(lazy(() => import('../pages/TruckDashboard')));

const Guide = Loadable(lazy(() => import('../pages/Guide')));

const routes: any[] = [
  {
    path: '/truck',
    element: (
      <TruckDashboard />
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <LandingScreen />
      </AuthGuard>
    )
  },
  {
    path: '/passenger/:viewId',
    element: (
      <GuestGuard>
        <PassengerView />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    element: (
      <GuestGuard>
        <DashboardLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/create-user',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <CreateUserForm />
          </RoleBasedGuard>
        )
      },
      {
        path: '/clients',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}>
            <Clients />
          </RoleBasedGuard>
        )
      },
      {
        path: '/clients/create',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}>
            <ClientCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: '/clients/:id',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_CLIENT_ADMIN]}>
            <ClientCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: '/home',
        element: (
          <RoleBasedGuard>
            <Home />
          </RoleBasedGuard>
        )
      },
      {
        path: '/studyArea',
        element: (
          <RoleBasedGuard>
            <StudyArea />
          </RoleBasedGuard>
        )
      },
      {
        path: '/studyArea/create',
        element: (
          <RoleBasedGuard>
            <CreateNewStudyArea />
          </RoleBasedGuard>
        )
      },
      {
        path: '/projects',
        element: (
          <RoleBasedGuard>
            <Projects />
          </RoleBasedGuard>
        )
      },
      {
        path: '/projects/:id',
        element: (
          <RoleBasedGuard>
            <Projects />
          </RoleBasedGuard>
        )
      },
      {
        path: '/collections',
        element: (
          <RoleBasedGuard>
            <Collections />
          </RoleBasedGuard>
        )
      },
      {
        path: '/collections/:id',
        element: (
          <RoleBasedGuard>
            <CollectionsViewPage />
          </RoleBasedGuard>
        )
      },
      {
        path: '/timeofDay',
        element: (
          <RoleBasedGuard>
            <TimePeriod />
          </RoleBasedGuard>
        )
      },
      {
        path: '/mapLayers',
        element: (
          <RoleBasedGuard>
            <MapLayers />
          </RoleBasedGuard>
        )
      },
      {
        path: '/mapLayers/create',
        element: (
          <RoleBasedGuard>
            <CreateMapLayer />
          </RoleBasedGuard>
        )
      },
      {
        path: '/mapLayers/:id',
        element: (
          <RoleBasedGuard>
            <UpdateMapLayer />
          </RoleBasedGuard>
        )
      },
      {
        path: '/zone-system',
        element: (
          <RoleBasedGuard>
            <ZoneSystems />
          </RoleBasedGuard>
        )
      },
      {
        path: '/zone-system/create',
        element: (
          <RoleBasedGuard>
            <CreateNewZoneSystem />
          </RoleBasedGuard>
        )
      },
      {
        path: '/equity/create',
        element: (
          <RoleBasedGuard>
            <CreateNewEquity />
          </RoleBasedGuard>
        )
      },
      {
        path: '/userGuides',
        element: (
          <RoleBasedGuard>
            <UserGuides />
          </RoleBasedGuard>
        )
      },
      {
        path: '/equity',
        element: (
          <RoleBasedGuard>
            <Equity />
          </RoleBasedGuard>
        )
      },
      {
        path: '/useCases',
        element: (
          <RoleBasedGuard>
            <UseCases />
          </RoleBasedGuard>
        )
      },
      {
        path: '/faqs',
        element: (
          <RoleBasedGuard>
            <FAQs />
          </RoleBasedGuard>
        )
      },
      {
        path: '/contactSupport',
        element: (
          <RoleBasedGuard>
            <ContactSupport />
          </RoleBasedGuard>
        )
      },
      {
        path: '/theme',
        element: (
          <RoleBasedGuard>
            <Theme />
          </RoleBasedGuard>
        )
      },
      {
        path: '/projectCreation',
        element: (
          <RoleBasedGuard>
            <ProjectCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: '/addDashboard/:id',
        element: (
          <RoleBasedGuard>
            <ProjectCreation />
          </RoleBasedGuard>
        )
      },
      {
        path: '/users',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <Users />
          </RoleBasedGuard>
        )
      },
      {
        path: '/organization',
        element: (
          <RoleBasedGuard RoutePermissions={[Permissions.ADD_USER]}>
            <Organization />
          </RoleBasedGuard>
        )
      },
      {
        path: '/myProfile',
        element: (
          <RoleBasedGuard>
            <Profile />
          </RoleBasedGuard>
        )
      },
      {
        path: '/guide',
        element: (
          <RoleBasedGuard>
            <Guide />
          </RoleBasedGuard>
        )
      }
    ]
  },
  {
    path: '*',
    element: (
      <ErrorGuard />
    )
  }
];

export default routes;
