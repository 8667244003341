export const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT
};

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const TimeOfBinCreationLimit = 12;

export const REACTS3URL = process.env.REACT_APP_S3_URL;
