import useCustomToken from '../hooks/useCustomToken';
import useTheme from '../hooks/useTheme';
import { locusTokenOverrides } from './common';
import { ConfigProvider } from 'antd';

export const withConfigProvider = (HOCComponent: any) => {
  const OutputComponent = () => {
    const token = useCustomToken();
    const { dynamicTheme } = useTheme();
    const { colorPrimary, colorBgBase, colorTextBase, colorSuccess, colorWarning, colorError, fontFamily } = dynamicTheme;

    return (
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: colorPrimary ?? locusTokenOverrides.colorPrimary,
            colorTextBase: colorTextBase ?? locusTokenOverrides.colorTextBase,
            colorBgBase: colorBgBase ?? locusTokenOverrides.colorBgBase,
            fontFamily: fontFamily ?? locusTokenOverrides.fontFamily,
            colorWarning: colorWarning ?? locusTokenOverrides.colorWarning,
            colorSuccess: colorSuccess ?? locusTokenOverrides.colorSuccess,
            colorError: colorError ?? locusTokenOverrides.colorError
          },
          components: {
            Menu: {
              itemHoverBg: 'transparent',
              itemBorderRadius: 2,
              itemHoverColor: colorPrimary ?? token?.colorPrimary,
              fontSize: token?.fontSizeLG,
              activeBarBorderWidth: 0,
              itemMarginInline: 3,
              itemSelectedBg: 'transparent',
              itemActiveBg: 'transparent'
            },
            Input: {
              colorBgContainer: 'transparent',
              borderRadius: 4,
              controlOutline: 'transparent',
              colorTextPlaceholder: token?.colorFill,
              colorTextDisabled: token?.colorTextTertiary
            },
            InputNumber: {
              colorBgContainer: 'transparent',
              borderRadius: 4,
              controlOutline: 'transparent',
              colorTextPlaceholder: token?.colorFill,
              colorTextDisabled: token?.colorTextTertiary
            },
            Segmented: {
              colorBgLayout: 'transparent',
              itemHoverBg: 'transparent',
              itemActiveBg: 'transparent',
              borderRadius: 4,
              itemSelectedBg: token?.colorFill,
              controlPaddingHorizontal: 7
            },
            Tabs: {
              colorBorderSecondary: 'transparent',
              horizontalItemPadding: '0px',
              verticalItemMargin: '0px',
              itemHoverColor: token?.colorPrimaryHover,
              colorText: token?.colorTextTertiary,
              horizontalMargin: '0',
              horizontalItemGutter: 20
            },
            Pagination: {
              colorBgContainer: 'transparent',
              borderRadius: 4
            },
            Descriptions: {
              itemPaddingBottom: 0,
            },
            Radio: {
              fontSize: token?.fontSizeSM,
              controlHeight: 27,
              borderRadius: 4,
              buttonPaddingInline: 20,
              colorBorder: 'transparent',
              colorPrimaryBorder: 'transparent',
              buttonBg: token?.colorFillSecondary,
              buttonCheckedBg: token?.colorFill
            },
            Table: {
              borderRadius: 8,
              fontSize: token?.fontSizeSM,
              colorBgContainer: token?.colorFillQuaternary
            },
            Button: {
              borderRadius: 4,
              colorBgContainer: 'transparent',
              controlHeight: 27
            },
            Select: {
              borderRadius: 4,
              colorBgContainer: token?.whiteBgColor,
              colorTextDisabled: token?.colorTextTertiary
            },
            Modal: {
              boxShadow: 'none',
              borderRadius: 4,
            },
            DatePicker: {
              colorBgContainer: 'transparent',
              colorBgElevated: token?.whiteBgColor,
              colorTextDisabled: token?.colorTextTertiary
            }
          }
        }}
      >
        {typeof HOCComponent === 'function' ? <HOCComponent /> : HOCComponent}
      </ConfigProvider>
    );
  };
  return <OutputComponent />;
};
