import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import './i18n';
import routes from './routes/Routes';
import useAuth from './hooks/useAuth';
import { TailSpin } from 'react-loader-spinner';
import { styled } from 'styled-components';
import { withConfigProvider } from './static/withConfigProvider';
import { ThemeProvider } from './contexts/ThemeContext';
import useCustomToken from './hooks/useCustomToken';
import { CollapseProvider } from './hooks/useCollapse';

const Container = styled('div')`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const App = () => {
  const content = useRoutes(routes);
  const { isLoading } = useAuth();
  const token = useCustomToken();
  const location = useLocation();
  const helpdesk = document.getElementById('launcher-frame');

  if (isLoading) {
    return (
      <>
        <Container>
          <TailSpin
            color={token?.colorTextTertiary}
          />
        </Container>
      </>
    );
  }

  if (helpdesk) {
    helpdesk.style.display = (location.pathname === '/') ? 'none' : 'block';
  }

  return (
    <ThemeProvider>
      <CollapseProvider>
        {withConfigProvider(<>{content}</>)}
      </CollapseProvider>
    </ThemeProvider>
  );
};

export default App;
