import { Navigate } from 'react-router';

const ErrorGuard = () => {
  if (localStorage.getItem('access-token')) {
    return <Navigate to="/home" />;
  }

  return <Navigate to="/" />;
};

export default ErrorGuard;
