import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Role, User } from '../types/user';
import { authenticateUser } from '../api/requests/authenticateUser';
import { Roles, Tokens } from '../constants';
import KeycloakServices from '../Keycloak-config';
import { getClientUser } from '../api/requests/user';
import { getAllAccessRoles } from '../api/requests/accessRole';
import { getDarkColor } from '../utils/utils';

interface State {
  user: User | null;
  permissions: string[];
  isLoading: boolean;
  isAuthenticated: boolean;
  clientUsers: User[];
  roles: Role[];
  setIsRender: (val: any) => void
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  user: null,
  permissions: [],
  clientUsers: [],
  roles: [],
  isLoading: true,
  isAuthenticated: false,
  setIsRender: (val: any) => { }
};

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    user: User | null;
    permissions: string[];
    isLoading: boolean;
    isAuthenticated: boolean;
    clientUsers: User[];
    roles: Role[];
    setIsRender: (val: any) => void
  };
};

type Action = InitializeAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { user, permissions, isLoading, isAuthenticated, clientUsers, roles, setIsRender } = action.payload;

    return {
      ...state,
      isAuthenticated,
      user,
      permissions,
      isLoading,
      clientUsers,
      roles,
      setIsRender
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<State>({
  ...initialState
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isRender, setIsRender] = useState<boolean>(false);

  const getClientUserList = async () => {
    let details: any = [];
    if (state?.user?.clientId) {
      const userDetails = await getClientUser(state?.user?.clientId ?? '');
      details = userDetails?.data?.rows?.length > 0 ? (
        userDetails?.data?.rows?.map((item: any) => ({
          ...item,
          backgroundColor: getDarkColor()
        }))
      ) : [];
    }
    const userDetails = localStorage.getItem(Tokens.accessToken) && await authenticateUser();
    const user: any = userDetails?.data?.user;

    dispatch({
      type: 'INITIALIZE',
      payload: {
        ...state,
        clientUsers: details ?? [],
        user,
        isLoading: false
      }
    });
  };

  const initialize = async (): Promise<void> => {
    try {
      const userDetails = localStorage.getItem(Tokens.accessToken) && await authenticateUser();
      const user: any = userDetails?.data?.user;
      const permissions: any = userDetails?.data?.permissions;
      let clientUserDetails: any;
      if (user.role.name !== Roles.SITE_ADMIN) {
        clientUserDetails = await getClientUser(user?.clientId);
      }
      const clientUsers: any = clientUserDetails?.data?.rows?.length > 0 ? (
        clientUserDetails?.data?.rows?.map((item: any) => ({
          ...item,
          backgroundColor: getDarkColor()
        }))
      ) : [];
      const rolesFetched = await getAllAccessRoles();
      const roles = rolesFetched?.data?.filter((item: any) => item.name !== 'Owner');
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user,
          permissions,
          isLoading: false,
          isAuthenticated: true,
          clientUsers,
          roles,
          setIsRender
        }
      });
    } catch (err: any) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user: null,
          permissions: [],
          isAuthenticated: false,
          isLoading: false,
          clientUsers: [],
          roles: [],
          setIsRender: (val: any) => { }
        }
      });
    }
  };

  useEffect(() => {
    KeycloakServices.initKeycloak(initialize);
  }, []);

  useEffect(() => {
    state?.user && getClientUserList();
  }, [isRender]);

  return (
    <AuthContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
